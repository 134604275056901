<template>
  <div>
    <titulo-turmas @cadastrar="openModal" class="mb-3"/>

    <tabela :items="turmas" @editar="openModal" @excluir="confirmarExclusao" />

    <modal-turma
      :form="modais.turmaDados"
      :exibir="modais.turma"
      @atualiza-tabela="getTurmas"
      @fechar="closeModal('turma')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services:
import TurmaCurriculo from '@/common/services/curriculo-escolar/turma.service';

// Components:
import Tabela from './components/Tabela.vue';
import ModalTurma from './components/Modal.vue';
import TituloTurmas from '@/views/academico/outras-instituicoes/turmas/components/TituloTurmas';
export default {
  components: {
    Tabela,
    ModalTurma,
    TituloTurmas,
  },
  data() {
    return {
      turmas: [],
      modais: {
        turma: false,
        turmaDados: {},
      },
    };
  },
  mounted() {
    this.getTurmas();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getTurmas() {
      this.$store.dispatch(START_LOADING);
      TurmaCurriculo.listarTodos()
        .then(({ data }) => {
          this.turmas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        'Você realmente quer deletar esta turma?'
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      TurmaCurriculo.excluir(item)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Turma deletada!');
          this.getTurmas();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = {});
    },
    closeModal(modal) {
      return (this.modais[modal] = false);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
