import ApiService from "@/common/api/api.service";

let apiBasePath = 'TurmaCurriculo'

const TurmaCurriculo = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir(turmaId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${turmaId}`)
        return data
    },
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    async listarTodos(){
        let { data } = await ApiService.get(`${apiBasePath}/listar-todos`)
        return data
    }
}

export default TurmaCurriculo;