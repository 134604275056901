<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    titulo="Turmas"
    :campos="$refs"
    id="modalTurmaCurriculo"
  >
    <div class="row">
      <div class="col-12">
        <input-text
          ref="descricao"
          v-model="form.descricao"
          :label="'Turma'"
          :placeholder="'Ex: 6ª série'"
          required
        />
      </div>
      <div class="col-12">
        <input-select-search
          ref="fase"
          v-model="form.fase"
          :label="'Fase'"
          :options="opcoes.fases"
          required
        />
      </div>
      <div class="col-12">
        <input-select-search
          ref="grupoTurma"
          v-model="form.grupoTurmaId"
          :label="'Grupo turma'"
          :options="opcoes.grupoTurma"
          required
        />
      </div>
    </div>
    <template v-slot:modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import TurmaCurriculo from '@/common/services/curriculo-escolar/turma.service';
import GruposTurmaService from '@/common/services/academico/gruposTurma.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import Modal from '@/components/modal/Modal.vue';
import { InputText, InputSelectSearch } from '@/components/inputs';
import helpers from '@/common/utils/helpers';

export default {
  components: {
    Modal,
    InputText,
    InputSelectSearch,
  },
  props: {
    id: { type: String, default: null },
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  data() {
    return {
      opcoes: {
        fases: [],
        grupoTurma: [],
      },
    };
  },
  mounted() {
    this.getFases();
    this.getGrupoTurma();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getFases() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('fase')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.fases = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGrupoTurma() {
      this.$store.dispatch(START_LOADING);
      GruposTurmaService.listarTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurma = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;

      !this.form.id ? this.criar() : this.editar();
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      TurmaCurriculo.criar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Turma criada!');
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      TurmaCurriculo.editar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Turma editada!');
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
